import { localeEn as leapPagesEn } from '-/leap-pages';
import { localeEn as leapStoriesEn } from '-/leap-stories';

export default {
  ...leapStoriesEn,
  ...leapPagesEn,
  actions: {
    save: {
      default: 'Save',
      inprogress: 'Saving',
      confirmLeave:
        'Are you sure you want to leave without saving your changes?',
    },
    copyToClipboard: 'Copied to clipboard',
    copyToClipboardCta: 'Copy link',
    delete: {
      default: 'Delete',
      inprogress: 'Deleting',
      withItem: 'Delete {{item}}',
      modalBody:
        "Once deleted, there's no turning back. Any content added will be lost 👻",
      confirm: 'Do it!',
      cancel: 'Cancel',
    },
  },
  emailConfirmModal: {
    title: 'Account verification required.',
    description:
      'Please verify your account via the email we sent upon sign-up. If you no longer have access to that email, please click the button below.',
    button: 'Resend email',
  },
  navigation: {
    home: 'Home',
    products: 'Products',
    store: 'Store',
    contacts: 'Contacts',
    email: {
      title: 'Email',
      badge: '(0)[0 left 🥲];(1-inf)[{{count}} left]',
    },
    calendar: {
      title: 'Calendar',
      events: 'Events',
      availability: 'Availability',
    },
    analytics: {
      title: 'Analytics',
      analyticsMarketingTooltip:
        'Analytics is here! 🤩 Track your store and product performance at a glance.',
    },
    flows: 'Flows',
    broadcasts: 'Broadcasts',
    upgrade: 'Try {{planName}} 🚀',
    settings: 'Settings',
    assistant: 'Assistant',
    referrals: 'Referrals',
    logout: 'Logout',
    copyUrl: 'Copy store link',
    copyUrlSuccess: 'Store link copied!',
    copyUrlError: 'Something went wrong',
    viewStore: 'View my store',
  },
  breadcrumbs: {
    add: 'Add',
    new: 'New',
    edit: 'Edit',
    generate: 'Generate',
  },
  generator: {
    back: 'Back to expertise',
    home: 'Home',
    backToProducts: 'Back to products',
    description: {
      title: "Let's create your product page automagically! ✨",
      caption: {
        EMAIL_CAPTURE:
          'Enter a brief description of what your customers will receive when they sign up.',
        ASK_ME_ANYTHING:
          'Enter a brief description of your niche or expertise, your target audience, and the topics they can submit questions on.',
        DIGITAL_DOWNLOAD:
          'Enter a brief description of your niche or expertise, your target audience, the format of your digital download, and the main pain point or problem it solves.',
        EBOOK:
          "Briefly outline the topic of your ebook, who it's written for, and the main benefits it delivers the reader.",
        WEBINAR:
          "Enter a brief description of the event, webinar or workshop you're hosting.",
        COACHING:
          'Please enter a brief description of your niche or expertise, your target audience, and describe the format of the sessions you wish to offer, for example: ',
      },
      captionDetails: {
        COACHING:
          'Discovery call, Work audit or critique, Client jam session, Strategy & goal setting session, Ongoing coaching & mentorship, or Tarot & astrology readings',
      },
      placeholder: {
        EMAIL_CAPTURE:
          'Customers signing up will receive a weekly newsletter with exciting news, events and information from the world of yoga.',
        ASK_ME_ANYTHING:
          'E.g. I am a certified yoga practitioner specializing in helping postnatal mothers get back to physical and mental wellbeing. Customers can ask me anything about their postnatal yoga routine.',
        DIGITAL_DOWNLOAD:
          'E.g. I am a certified yoga practitioner specializing in helping postnatal mothers. My printable template helps my audience quickly identify the most appropriate post-partum recovering stretches',
        EBOOK:
          'E.g. A comprehensive guide to practicing yoga and finding a mental and physical balance in life. My ebook helps beginners find a yoga sequence that suits their personal needs and abilities.',
        WEBINAR:
          'A weekly online motivational meet-up, where my community gets together to share stories about their journey through my yoga program.',
        COACHING:
          'E.g. I am a social media strategist working with small businesses and solopreneurs. During these sessions I help my clients by auditing their online presence, pinpointing areas for improvement, and working with them to craft bespoke strategies.',
      },
      submit: {
        idle: 'Generate product page',
        loading: 'Generating your product page',
        submitting: 'Generating your product page',
      },
      loading: {
        phrase1:
          'Generating your landing page! Hang tight, magic takes a minute 🪄',
        phrase2:
          "We've made generating a landing page as simple as writing a social media post.",
        phrase3:
          "What you're about to see is just a starting point - customize it however you like",
        phrase4: "It's still missing the secret ingredient 😉",
      },
    },
    expertise: {
      title: "Let's create your {{product}} automagically! 💸",
      caption:
        'Quick question: what does your audience see you as an expert on?',
      placeholder:
        'One sentence will do. We recommend focusing on one area of expertise per product.',
      submit: {
        idle: 'Next',
        loading: 'Next',
        submitting: {
          'mini-course': 'Generating learning outcomes',
          guide: 'Generating topics',
          challenge: 'Generating challenge ideas',
          tutorial: 'Generating tutorial tasks',
        },
      },
    },
    topics: {
      title: {
        'mini-course': "Now let's pick a learning outcome",
        guide: "Now let's pick a topic",
        challenge: "Now let's pick a challenge theme",
        tutorial: "Now let's pick a tutorial focus",
      },
      caption: {
        'mini-course':
          'Mini courses work best when focused on a small promise that your audience can achieve in 30 minutes or less. Choose one from the options below.',
        guide: 'Choose one from the options below.',
        challenge: 'Choose one from the options below.',
        tutorial: 'Choose one from the options below.',
        default: 'Choose one from the options below.',
      },
      showMore: 'Show me more',
      notQuiteRight: '🤔 Not quite right? Enter your own',
      backToTopics: 'Show other options',
      enterOwnTopic: 'Enter your own below. One sentence will do!',
      customTopic: 'Your idea here',
      topicEmptyFill: 'Your topic is empty, please fill it in!',
      topicTooShort: 'Your topic is too short, please elaborate!',
      topicTooLong: 'Your topic is too long, please shorten it!',
      submit: {
        idle: "Let's do it!",
        submitting: 'Generating your {{product}} draft',
        loading: "Let's do it!",
      },
    },
    outline: {
      modal: {
        title: 'Say hello to your {{product}} 🥳',
        caption1:
          "We've created a draft to help get you started, and it's only missing one key ingredient: You!",
        caption2:
          'Review your {{product}} draft and customize it to your liking. Be sure to hit save if you like what you see!',
        cta: 'Got it!',
      },
      apiError: {
        rateLimit:
          "Sorry, we're experiencing high AI demand right now. Please retry later.",
        tokenLength:
          "Sorry, we're experiencing high AI demand right now and couldn't complete this product. You can save or try again later.",
        unknown:
          "Sorry, we're experiencing a problem with AI requests. Please retry later.",
      },
      failedModal: {
        rateLimit: {
          title: "Oh snap! There's high AI demand right now 😥",
          caption:
            'Sorry, we aren\'t able to generate your product at the moment. Please retry later or use the "Build from Scratch" option on your home dashboard.',
          cta: 'Go to products',
        },
        tokenLength: {
          title: "Oh snap! There's high AI demand right now 😥",
          caption:
            "Sorry, we can't complete this product at the moment. You can save what's here or retry later.",
          cta: 'Close and go save',
        },
        unknown: {
          title: 'Oh snap! The robot is out for lunch 🤖',
          caption:
            'Apologies, something\'s amiss. We\'re on it. Please retry later or use the "Build from Scratch" option on your home dashboard.',
          cta: 'Go to home dashboard',
        },
      },
      submit: {
        idle: 'Save {{product}}',
        submitting: 'Saving {{product}}',
        loading: 'Saving {{product}}',
      },
      loading: {
        phrase1:
          'Generating your {{product}} draft. Hang tight, magic takes a minute 🪄',
        phrase2: "We've made building a {{product}} as easy as creating a Reel",
        phrase3:
          "What you're about to see is just a starting point - customize it however you like",
        phrase4: "It's still missing the secret ingredient 😉",
      },
    },
    skip: "Skip, I don't need any help",
    templates: {
      'mini-course': 'mini-course',
      challenge: 'challenge',
      guide: 'guide',
      tutorial: 'tutorial',
    },
    warnings: {
      unsavedChanges: 'Changes you made may not be saved.',
      leavePage:
        'Are you sure you want to leave? Your learning outcome suggestions will be lost.',
    },
    errors: {
      missingNiche:
        'Oops! We need a topic to help you get started. Please add a topic before generating learning outcomes.',
      unsafeNiche:
        "Oops! We can't generate learning outcomes for that topic. Please try a different topic.",
    },
    instagram: {
      home: 'Home',
      title:
        "Let's create your personalized content based on Instagram automagically! 🎥",
      description:
        "Our AI Social Transformer will create your content for you. Let's get started!",
      submit: {
        idle: "Let's do it!",
        loading: 'Loading',
        submitting: 'Generating...',
      },
    },
  },
  home: {
    welcome: 'Welcome',
    getStarted: 'Get started',
    checklist: {
      item1: {
        title: 'Set up my creator store',
        description:
          'Create a place to showcase your products and services, share your link and start driving traffic today.',
        buttonText: 'Customize store',
      },
      item2: {
        title: 'Sell an existing product or service',
        description:
          'Start selling right now, by offering products including Digital downloads, Ask Me Anything services and Coaching.',
        buttonText: 'Sell what you have',
      },
      item3: {
        title: 'Create a mobile product with AI',
        description:
          'Creators with mobile products earn 64% more. Create one from scratch in minutes.',
        buttonText: 'Create a product',
      },
      item4: {
        title: 'Launch an email campaign',
        description:
          'Build deeper relationships with your contacts, put lead nurturing and selling on auto-pilot.',
        buttonText: 'Start emailing',
      },
    },
    flexiblePaymentsBanner: {
      heading: 'Flexible payment & selling options are now here! 🤑',
      description:
        '<0>Buy now, pay later</0>, <0>Digital wallets</0>, and <0>Coupons</0> are now available to help you convert customers.',
      descriptionForExpressCustomers:
        '<0>Buy now, pay later</0>, and <0>Digital wallets</0> are now available to help you convert customers.',
      learnMore: 'Learn more',
      ctaButtonText: 'Set up in Stripe',
    },
    assistantBanner: {
      heading: 'Need some help getting started?',
      description:
        'Your Assistant can help you brainstorm product ideas, create products and draft emails for you!',
      cta: 'Create with your Assistant',
      imageAlt:
        'Example prompts for our AI assistant, including: brainstorm ideas, draft a newsletter and create products',
    },
    bookMyTimeBanner: {
      heading: '<0>Book my time</0> is now available on The Leap 🤩',
      description:
        'Transform your time into a high value product by selling your calendar availability on The Leap - no more need for Calendly!',
      cta: 'Try it out',
      imageAlt: 'Example of a book my time products',
    },
    referralBanner: {
      heading: 'Share The Leap and earn extra cash 🤑',
      description:
        'Refer creators to The Leap and earn up to 40% of what they pay.',
      cta: 'Grab your referral link',
      imageAlt:
        'Image showing the steps to get referrals. 1: Get the referral link. 2: Share with your network. 3: Watch referrals roll in',
    },
  },
  dashboard: {
    goals: {
      badge: 'Goals 🙌',
      launchPlan: {
        title: 'Your launch plan 🚀',
        srStepDescription: 'Day {{index}}',
        caption:
          "Start your product-based business in three simple steps. Let's do this!",
        customizeStore: {
          title: 'Customize your creator store',
          caption:
            'Add a picture, link socials, choose a theme, and use your store link as your bio link. Ready?',
        },
        publishLeadMagnet: {
          title: 'Publish a lead magnet',
          caption:
            "Something you've already created works great here, like a template, checklist, or cheat sheet. Starting fresh? Build something in minutes with The Leap using AI.",
        },
        shareWithAudience: {
          title: 'Share with your audience',
          captionStart: 'Check out',
          captionTips: 'these tips',
          captionEnd: 'on promoting your lead magnet.',
        },
        letsGo: "Let's go!",
        imgAlt: {
          pinkStar: 'Pink star',
          personWatchingPhone:
            "A person watching a creator's video on their phone",
        },
      },
    },
  },
  stickyBanner: {
    reminder:
      'Reminder: Your exclusive Founders discount expires on June 30th.',
    redeemOffer: 'Redeem 80% off',
    learnMore: 'Learn more',
  },
  downgradeBanner: {
    reminder:
      'When your current plan expires on {{date}}, the 3 live products at the top of your store will remain live, and the rest will be hidden. Make sure you select the 3 products you wish to keep live by moving them to the top of your store.',
    learnMore: 'See how here.',
  },
  settings: {
    successMessage: 'Awesome! Your {{name}} has been successfully saved!',
    profile: 'profile',
    storeChanges: 'store changes',
    accounts: {
      title: 'One-click sign in',
      description: 'Connect to your account to enable one-click sign in ',
      connectText: 'Connect',
      connect: {
        google: 'Google',
        tiktok: 'TikTok',
        instagram: 'Instagram',
        thinkific: 'Thinkific',
      },
      connected: 'Connected',
      oauth: {
        invalidCode: 'Invalid authentication code, please try again.',
        invalidState: 'Invalid authentication state, please try again.',
        invalidProvider: 'Invalid authentication provider, please try again.',
        success: {
          instagram: 'Successfully connected your Instagram account!',
          tiktok: 'Successfully connected your TikTok account!',
          google: 'Successfully connected your Google account!',
          google_calendar: 'Successfully connected your Google Calendar!',
          thinkific: 'Successfully connected your Thinkific account!',
        },
      },
    },
    general: {
      payments: {
        caption: "Let's get that money rolling in 🤑",
        paymentMethods: 'Payment methods',
        stripe: 'Stripe',
        setupStripeCta: 'Connect',
        continueSetupStripeCta: 'Finish setup',
        successfullyConnected: 'Fully connected and accepting payments',
        viewReports: 'Open Stripe',
        countryList: 'Country of residence',
        errors: {
          countryNotSupported:
            "Sorry, we don't support the selected country at this time! 😭",
          missingInfoStripe:
            'Your Stripe account is missing some info before it can be used to capture payments.',
          paymentRejected:
            'Payments have been deactivated on this account, please contact our support team for more information beta@theleap.co',
          paymentPaused:
            'Payments have been paused on this account, please contact our support team for more information beta@theleap.co',
        },
        dynamicPayments: {
          label: 'Flexible payment & selling options',
          description:
            'Offer customers flexible payment options like Buy now, Pay later and one-click digital wallets like Apple Pay and Google Pay.',
          descriptionLine2:
            "Note: available options depend on your Stripe currency and your customer's location.",
          confirmationModal: {
            title: 'Confirm enabling payment options',
            description: 'Fees may be applicable on transactions.',
            confirm: 'Yes, enable payment options',
            cancel: 'Cancel',
            checkOnStripe: 'Check your local rates here',
          },
        },
        localCurrency: {
          title: 'Store currency',
          description:
            'Set your store currency to match your Stripe currency to unlock more flexible payment & selling options.',
          cta: 'Change store currency',
          confirmationModal: {
            title: 'Are you sure?',
            description:
              'Note: your product price values will NOT change, only the currency will.',
            confirm: 'Confirm currency change',
            cancel: 'Cancel',
          },
        },
        couponCodes: {
          header: 'Coupon codes',
          description:
            ' Set up discount and promotional coupons in your Stripe portal.',
          ctaButton: 'Go to Stripe Coupons',
        },
        localCurrencyUpdated: 'Default currency has been updated successfully',
      },
    },
    billing: {
      details: {
        title: 'Billing',
        description: 'Your plan details',
        per: '{{currency}}\nper {{interval}}*',
        cancelled: '(Cancelled)',
        accessEndDate: 'Access end date: {{date}}',
        viewInvoices: 'View billing invoices',
        renewSubscription: 'Renew subscription',
        nextBillingDate: 'Next billing date: {{date}}',
        manageBilling: 'Manage billing method',
        cancel: 'Cancel plan',
        lowestPlan: {
          name: 'Free',
        },
        intervals: {
          MONTH: 'month',
        },
        taxDisclaimer: '* Plus applicable taxes',
        discountDisclaimer:
          '† Actual billed amounts and any applicable discounts can be viewed on Stripe portal invoices',
      },
      plans: {
        per: '{{currency}}\nper {{interval}}*',
        currentSubscription: 'Current plan',
        tryPlan: 'Try {{planName}}',
        cancelPromise: 'You can cancel anytime!',
        features_zero: 'This includes:',
        features_one: 'Includes everything on {{prevPlanName}} and:',
        features_other: 'Includes everything on {{prevPlanName}} and:',
        intervals: {
          MONTH: 'month',
        },
      },
      cancelationModal: {
        title: 'Are you sure you want to cancel your plan?',
        description: 'Switching to the Free plan will result in the following:',
        items: {
          liveProducts: {
            title: 'Reduced access to live products',
            description:
              'Your limit will be reduced from {{liveProductLimit}} live products to 3. If you cancel, please make sure you select the 3 products you wish to keep live by moving them to the top of your store.',
            link: 'https://www.loom.com/share/1da2cd905cd94256836fc35b93a0c40f',
            linkText: 'See how here',
          },
          contacts: {
            title: 'Reduced access to contacts',
            description:
              "You'll no longer be able to view, access or send emails to contacts beyond the first 500 created. If you cancel, we will continue to collect new contacts on your behalf however, in case you decide to renew your plan.",
          },
          zapier: {
            title: 'Zapier will be disconnected',
            description:
              'Any zaps or custom workflows set up will no longer be connected.',
          },
          googleCalendar: {
            title: 'Google calendar will be disconnected',
            description:
              'You will no longer be able to have calendar events automatically created for Book My Time sessions.',
          },
          csvExports: {
            title: 'Exports will be disabled',
            description:
              'You will no longer be able to export lists of contacts or orders.',
          },
        },
        confirmCancelation: 'Yes, I still want to cancel',
        keepPlan: "No, I'd like to stay where I am",
      },
      successMessage:
        'Welcome to your new plan! Please note, it may take a couple of minutes for some of your new features to appear.',
    },

    title: 'Settings',
    tabs: {
      store: 'Store',
      general: 'General',
      billing: 'Billing',
      accounts: 'Accounts',
      zapier: 'Zapier',
    },
    genericError:
      'Something went wrong, please check your information and try again.',
    socialMediaInputError:
      'Oops, one of the URLs provided is not in the correct format! for instance, please make sure your URL includes either http:// or https://',
  },
  blockBuilder: {
    tabs: {
      build: 'Build',
      setup: 'Setup',
      market: 'Market',
      advanced: 'Advanced',
    },
    liveProductUpgradeModal: {
      title: "You've hit your live product limit!",
      textLine1:
        'To set another product live, please switch one of your existing products to hidden.',
      textLine2:
        'Alternatively, you can increase your live product limit by upgrading!',
      buttonText: 'Try {{planName}} 🚀',
    },
    untitled: 'Untitled {{blockName}}',
    fields: {
      description: 'Description',
      url: 'URL',
      referralUrl: 'Referral URL',
    },
    events: {
      title: 'Event',
      label: 'Calendar invite url',
    },
    coaching: {
      BMTLearnMore: 'Learn more about Book my time',
    },
    save: {
      setToLive:
        '🎉 Congratulations on launching your {{name}}! Time to share with your audience',
      default: 'Awesome! Your {{name}} has been successfully saved!',
    },
    action: {
      googleCalendarDisconnected: 'Google Calendar disconnected',
    },
    kbArticleLink: 'Learn more about {{linkSubject}}',
    characterCount: 'Characters left',
    documentCharacterCount: 'Characters left: {{charactersLeft}}/{{maxLength}}',
    buildProduct: {
      tabTitle: 'Build my product',
    },
    fileUpload: {
      title: 'Add file',
      fromComputer: 'From my computer',
      fromWeb: 'From the web',
      url: 'URL',
    },
    coachingForm: {
      title: 'Booking options',
      leapEventBooking: {
        label: "Use The Leap's in-built booking tool",
        eventDuration: 'Duration',
        disconnectCalendarConfirmationModal: {
          title: 'Disconnect Google Calendar',
          description:
            'If you disconnect Google Calendar, all future Book my time sessions will be set to the default location option.',
          confirm: 'Disconnect Google',
          cancel: 'Cancel',
        },
        connectGoogleCalendar: {
          connect: 'connect',
          description: 'Connect your Google Calendar to enable Google Meet',
        },
        locationDropdown: {
          title: 'Location',
          connect: 'Connect',
          disconnect: 'Disconnect',
          upgrade: 'Upgrade to connect 🚀',
          googleCalendarUpgradeModal: {
            title: 'Upgrade to automate invites and meeting links',
            textLine1:
              'Reduce your admin tasks! Connect your Google account to automatically create meeting links and calendar invites for your customers.',
            buttonText: 'Upgrade to {{planName}} 🚀',
          },
          default: {
            label: 'Default',
            description:
              'You send a meeting link or phone number to your customer, manually.',
          },
          googleMeet: {
            label: 'Google Meet',
            description:
              'We create a calendar event and meeting link for you and your client, automatically.',
          },
          connectGoogleCalendar: 'Connect Google Calendar',
        },
        location: 'Location',
        minutes: 'mins',
        manageAvailability: '<0>Manage your availability</0>',
        setAvailability:
          '<0>Set availability</0> to make booking times available',
      },
      externalEventBooking: 'Link to an external booking tool',
    },
    blockStatus: {
      default: 'Status',
      byBlock: '{{blockType}} status',
      live: {
        default: 'Live',
        description:
          '{{blockType}} is visible on your store and checkout is enabled.',
      },
      hidden: {
        default: 'Hidden',
        description:
          '{{blockType}} is hidden from your store and checkout is disabled (customers that have already purchased will retain access).',
        cannotPurchase:
          '{{blockType}} is hidden in your store and cannot be purchased.',
      },
      saveWork: "Hit save to set this to 'Live'",
      product: {
        livePricingError:
          "Product cannot be set live as payments have not been set up on your account. Visit settings to set up payments, or switch product to 'Lead magnet' pricing option.",
      },
      setupPayments: {
        info: 'Setup your payment information to change the status and start selling.',
        button: 'Setup payments',
      },
    },
    shareProduct: {
      tabTitle: 'Share my product',
      learnMore: 'Learn more',
      salesPage: {
        title: 'Product landing page',
        guidance:
          'Describe your product in full detail and add an exciting banner image across the top of the page. This is where your audience decides to purchase your product.',
        description: {
          label: 'Product description',
          characterCount: 'Characters left:',
        },
        bannerImage: {
          label: 'Banner image',
          aspectRatio: 'PNG, JPG, or GIF up to 10MB. Aspect ratio of 16:9',
        },
        previewButton: 'View page',
        share: 'Share',
        closePanel: 'Close panel',
        hideButton: 'Hidden Button',
        reviewProductDescription:
          "Review the product description then click Share to engage your audience. No one can access your product while it's Hidden.",
      },
      pricing: {
        title: 'Pricing',
        amount: 'Amount',
        formattedPrice: '{{currencySymbol}}{{amount}} {{currency}}',
        leadMagnet: {
          title: 'Lead magnet',
          details: 'Offer your product in exchange for email address',
        },
        oneTimePayment: {
          title: 'Fixed price',
          details: 'Sell your product for a price of your choosing',
        },
        payWhatYouWant: {
          title: 'Pay what you want',
          details: 'Allow each customer to choose their own price',
        },
        amountCaption:
          'Please ensure the amount is greater than 0.5 USD and less than 999,999.99 USD, or its equivalent in your local currency.',
        notConnectedHeader:
          'Setup your payment methods to publish and start selling your products',
        notConnectedMessage:
          'Enable Buy now, Pay later options, One-click payments and create coupons in your Stripe portal. Checkouts with these features earn 14% more on average.',
        setupCta: 'Setup payments',
        paymentMethods: {
          cardMessage:
            'Turn on buy now and pay later, one-click pay options or promotional coupons by enabling them in your Stripe portal.',
          cardMessageForStripeExpress:
            'Turn on buy now and pay later, or one-click pay options by enabling them in your Stripe portal.',
          learnMore: 'Learn more',
        },
      },
      storeCard: {
        title: 'Store card',
        guidance:
          'This is what your audience sees when they view the list of products in your store. Add a compelling teaser and thumbnail image for your card',
        description: {
          label: 'Product teaser',
          characterCount: 'Max characters allowed: {{maxLength}}',
        },
        titleLabel: 'Title',
        ctaInput: {
          label: 'Button text',
          placeholder: {
            oneTimePayment: 'Buy for {{formattedPrice}}',
            free: 'Get it free',
            emailCapture: 'Get it free',
            payWhatYouWant: 'Pay what you want',
            open: 'Open link',
            signup: 'Sign up now',
          },
          caption:
            'If custom text is not provided the placeholder text will appear on your store card.',
        },
        checkoutButton: {
          caption:
            'If custom text is not provided the placeholder text will appear on your product landing page.',
          label: {
            default: 'Proceed to checkout',
            emailCapture: 'Sign me up',
            oneTimePayment: 'Proceed to checkout',
            payWhatYouWant: 'Proceed to checkout',
          },
        },
        image: {
          aspectRatio: 'PNG, JPG, or GIF in square aspect ratio',
        },
        previewButton: 'View product on store',
        viewOnStore: 'View on store',
        notVisible: 'Not visible',
        hiddenProductsStorefront:
          "Hidden products aren't visible on your storefront",
      },
      shareModal: {
        title: 'Share your product landing page 🎉',
        failedToCopy: 'Failed to copy text',
        copyClipboardSuccess: 'Copy to clipboard successful!',
        copyLink: 'Copy link',
        lookingMorePlaceShare:
          'Looking for more places to share it? Here are some ideas 💡',
        postSocialMediaChannels: 'Post about it on your social media channels',
        sendEmailSubscribers: 'Send an email to your newsletter subscribers',
        dmActiveFollowers: 'DM some of your most active followers',
        checkOut: 'Check out',
        promoteDigitalProducts: 'How to Promote Digital Products',
        promotionStrategies: 'for more promotion strategies.',
      },
      previewLandingPage: 'Preview landing page',
      previewCard: 'Preview store card',
    },
    advanced: {
      tabTitle: 'Advanced',
      testimonials: 'Testimonials',
      description:
        'Add any praise you have received from your customers here to display it on the sales page.',
      addTestimonial: 'Add testimonial',
    },
    validText: 'Link text is required',
    validUrl: 'Please enter a valid URL that starts with http or https.',
    validYoutubeUrl: 'Please enter a valid link to a YouTube video',
    applyLink: 'Add link',
    removeLink: 'Remove',
    linkTextLabel: 'Link text',
    linkUrlLabel: 'URL',
    linkButtonLabel: 'Show as button',
    linkPopupTitle: 'Insert link',
    videoPopupTitle: 'Embed Youtube video',
    imagePopupTitle: 'Insert image',
    imageUrl: 'Image URL',
    insertImage: 'Insert image',
    videoUrl: 'Youtube URL',
    embedVideo: 'Embed video',
    embedVideoCaption: 'Paste a Youtube link to embed the video',
    error: {
      generic:
        'Something went wrong, please check your information and try again.',
    },
    referralLinkDefaults: {
      title: 'Set up your own store on The Leap! 🚀',
      description: 'Join 30,000+ creators who run their business on The Leap.',
      cta_label: 'Sign up free',
    },
  },
  assistant: {
    nav: {
      reset: 'Reset',
      cancel: 'Cancel',
    },
    submit: 'Send',
    inputField: {
      caption:
        'AI Assistant is still learning and can make mistakes. Please check important information',
    },
  },
  referrals: {
    header: 'SHARE & EARN',
    subHeader: 'Share The Leap and earn up to 40% commission',
    grabLink: 'Grab your referral link below',
    copyToShare: 'Copy to share',
    copyReferralLink: 'Copy referral link',
    copied: 'Link copied!',
    failedToCopy: 'Failed to copy link',
    learnMore: 'Learn more about the referral program',
    totalReferrals: 'Total referrals',
    paidReferrals: 'Paid referrals',
    totalEarned: 'Total earned',
    comingSoon: 'Coming soon',
    optIn: {
      header: 'Welcome to The Leap Referral Program 🎁',
      subHeader:
        'Love The Leap? Spread the word and earn a share of what you refer.',
      bullet1: 'Grab your unique referral link and start sharing',
      bullet2: 'Earn up to 40% of what your referrals pay',
      bullet3: 'The more you refer, the more you earn',
      identifyQuestion: 'Which option describes you best?',
      termsCheckbox: 'Opt-in to join The Leap Referral Program',
      termsDescription:
        'By opting in, you acknowledge that you have read and agree to The Leap’s <referralTerms>Referral Program Terms & Conditions</referralTerms>, <termsOfService>Terms of Service</termsOfService> and <privacyPolicy>Privacy Policy</privacyPolicy>, as provided by Thinkific Labs Inc. (“Thinkific”), and you consent to the collection and use of your personal information in accordance with these documents.',
      buttonText: 'Join referral program',
      selectOne: 'Select one',
      options: {
        loveLeap: 'I just love The Leap!',
        affiliate: 'Affiliate marketer',
        coach: 'Coach or consultant',
        influencer: 'Social media influencer',
      },
      learnMore: 'Learn more about the referral program',
      successMessage: 'You have successfully opted in to the referral program!',
      errors: {
        terms: 'Please accept the terms and conditions to continue',
        identification: 'Please identify yourself to continue',
      },
    },
  },
  accountDetails: {
    title: 'Account details',
    email: 'Account email address',
    emailHelpText: 'Used for communication from The Leap platform.',
    unconfirmedEmail:
      'Want to change your account email address? Enter the new address here:',
    updateEmail: 'Update email address',
    info: 'Head to your inbox to confirm the new address.',
  },
  leapBranding: {
    locked: {
      title: 'Unleash your brand: Upgrade to remove our logo 🌟',
      caption:
        "Remove the Leap's promotional logo on your creator store, marketing emails, product & thank you pages.",
      imgAlt:
        "Image depicting the areas of the Leap app where the Leap's branding will appear to your users",
      upgradeButton: 'Make it yours with {{planName}} 🚀',
    },
    unlocked: {
      title: 'Control Leap branding',
      caption:
        'The Leap branded footer appears on your creator store, marketing emails, product and thank you pages. Turn it off to remove Leap branding from your audience views.',
    },
  },
  accountDelete: {
    title: 'Delete my Leap account',
    description:
      'Delete your account data, products, links and any leads and sales stored on the Leap.  Stripe transaction records will remain on Stripe.',
    button: 'Delete account',
    deletionFailed: 'Account deletion has failed, please try again.',
    modal: {
      title: 'Are you sure you want to delete your account?',
      point1:
        'Your customers will lose access to any products purchased from you',
      point2:
        'Your store will no longer be visible, and your unique handle will be available for others to claim',
      point3:
        'All products and landing pages created, including any videos and images uploaded will be permanently deleted',
      point4: 'All Leads and Sales data will be permanently deleted',
      point5: "We'll no longer be friends 💔...jk we still love you",
      footer:
        'Please make sure you save any content you might need from the Leap before deleting your account!',
      cancel: 'Cancel, keep account',
      confirm: 'Yes, delete my account',
    },
  },
  myStore: {
    poweredBy: 'Powered by',
    theLeap: 'The Leap',
  },
  reports: {
    title: 'Reports',
    description:
      'Report will be generated and sent to your email as a CSV file.',
    sales: {
      title: 'Sales report',
      button: 'Export to email',
    },
    leads: {
      title: 'Leads report',
      button: 'Export to email',
    },
    zapier: {
      title: 'Connect to over 6,000 apps with Zapier',
      description:
        'Say goodbye to manual data exports. Trigger automatic workflows to your email or CRM tool whenever you capture a lead or make a sale!',
      learnMore: 'Learn more',
      goToZapierSettings: 'Connect now',
    },
  },
  store: {
    title: 'Store',
    viewStore: 'View store',
    onboardingHighlight: '👋 Customize your store, then click here to see it!',
    profileTab: 'Profile',
    storeProductsTab: 'Products',
    appearanceTab: 'Appearance',
    profile: {
      title: 'Profile',
      handle: {
        label: 'Handle',
        validation: {
          handleLengthInvalid:
            'Your handle must be between 2 and 30 characters.',
          handleInvalidCharacters:
            "Your handle can include letters, numbers, and only '.' or '_'",
        },
      },
      description: 'Description',
      characterCount: 'Characters left',
      defaultDescription:
        'Welcome to my store. Explore my curated recommendations and discover something new today!',
      fieldImage: '{{field}} Image',
      socialMedia: {
        title: 'Social icons',
        buttonText: '+ Add icon link',
        delete: 'Delete',
        drag: 'Drag to reorder',
      },
    },
    theme: {
      placeholders: {
        creator: 'Creator',
        bio: 'My profile summary',
        blockTitle: 'My product',
      },
      presetPickerOption: 'The {{preset}} preset: {{descriptor}}',
      preview: 'Preview',
      title: 'Theme',
      viewStore: 'View my store',
      removeBranding: 'Remove Leap Footer',
    },
    storeProducts: {
      addSeparator: 'Add separator',
      untitled: 'Untitled separator',
      separatorForm: {
        title: 'Title',
        titlePlaceholder: 'Enter your separator title',
        subtitle: 'Subtitle',
        subtitlePlaceholder: 'Enter your separator subtitle',
        characterCount: 'Characters left',
        done: 'Done',
        delete: 'Delete',
      },
    },
  },
  products: {
    error: {
      generic:
        'Something went wrong, please check your information and try again.',
    },
    untitled: 'Untitled {{blockName}}',
    new: {
      back: 'Back',
      backToProducts: 'Back to products',
      addProduct: 'Add product',
      tooltip: '👋 Create a product from scratch in just a few minutes!',
      sellExistingProductTooltip:
        '👋 Choose a product type and start selling in minutes!',
      referralLinkTooltip:
        '🤑 New referral link! Add a Leap referral link to your store and start earning commission.',

      templatesTitle: 'Build and sell a product on The Leap:',
      aiMagicAvailable: 'AI magic available',
      productsTitle: 'Sell an existing product or service:',
      pageTitleNoStorefront: 'My mini-course',
      miniCourse: 'Mini-course',
      miniCourseCaption:
        'Deliver a learning outcome for your audience through micro-learning.',
      guide: 'Guide',
      guideCaption:
        'Provide an overview to help your audience form an opinion, make a decision, or take action.',
      tutorial: 'Tutorial',
      tutorialCaption:
        'Offer a step-by-step demonstration to enable your audience to accomplish a specific task.',
      challenge: 'Challenge',
      challengeCaption:
        'Help your audience progress towards a goal by breaking it down into daily/weekly actions.',
      custom: 'Custom',
      customCaption: 'Build your own digital product from scratch.',
    },
    preview: {
      previewCta: 'Preview',
    },
    url: 'URL',
    live: 'Live',
    hidden: 'Hidden',
    leadMagnet: 'Lead Magnet',
    add: 'Add',
    build: 'Build',
    buildOptions: 'Build options',
    buildWith: 'Build with:',
    generateWithAi: 'Generate with AI',
    buildFromTemplate: 'Build from template',
    pageTitle: 'Products',
    savingStateCta: 'Saving',
    saveCta: 'Save',
    cancel: 'Cancel',
    addProduct: 'Add product',
  },
  legalAddress: {
    validation: {
      default: 'Invalid or missing fields.',
      streetAddress: {
        too_small: 'Street address is too short',
      },
      addressLine2: {
        too_small: 'Address line 2 is too short',
      },
      postalCode: {
        too_small: 'Postal code is too short',
      },
      state: {
        too_small: 'State is too short',
      },
      city: {
        too_small: 'City is too short',
      },
      country: {
        too_small: 'Country is too short',
      },
    },
  },
  BROADCAST: {
    title: 'Email',
    tabs: {
      flow: 'Flows',
      broadcast: 'Broadcasts',
    },
    triggers: 'Recipients',
    dateSent: 'Date sent',
    newBroadcast: 'New broadcast',
    mainTitle: 'Email broadcasts',
    mainDescription:
      'Connect, nurture, and grow your relationships with one-off email campaigns. Create your own, or choose from one of our expertly designed creator templates.',
    defaultFlowTitle: 'Untitled broadcast',
    newFlowPage: {
      title: 'New Broadcast',
      description:
        'Select from our library of expert-crafted email templates. Use the broadcasts as they are or add your own flair to personalize them.',
      try: 'Try',
      buildFromScratch: 'Build from scratch',
    },
    deleteBroadcast: 'Delete broadcast',
    deletion: {
      title: 'Are you sure?',
      body: "Once deleted, there's no turning back. Your broadcast will be lost 👻",
      cancel: 'Cancel',
      doIt: 'Yes, delete it',
      deleting: 'Deleting...',
    },
    editAddress: 'Edit physical address',
    successMessage: {
      ACTIVE:
        'Broadcast is active! 🎉 Contacts will start receiving your email soon.',
      DRAFT: 'Broadcast is in draft.',
      default: 'Awesome! Your broadcast has been successfully saved!',
    },
    validation: {
      base: {
        emptyTrigger: 'Oops, please select recipients before saving',
        default:
          'Invalid or missing information, please review the emails and try again',
        maliciousLinkDetected:
          'Malicious link detected. Email is blocked from sending.',
      },
      rules: {
        invalid_type: "Triggers can't be empty",
        custom: "Triggers can't be empty",
      },
      subject: {
        too_small: "Subject can't be empty",
      },
      body: {
        too_small: "Body can't be empty",
      },
      delay: {
        too_big: "Timing can't be more than 7 days",
        too_small: 'Timing needs to be at least 1 minute',
      },
    },
    templates: {
      newsletter: {
        card: {
          title: 'Newsletter',
          description:
            'Share exciting updates, news, events and information from your world',
          badge: 'Nurture 🤩',
        },
        emails: {
          0: {
            subject: "🌟 This Week's Exciting Updates, Just for You!",
            bodyParagraph1: 'Hi there!',
            bodyParagraph2:
              "I hope this message finds you well and thriving! I've been hard at work recently, developing some thrilling new content that I can't wait to share with you. Here's this week's exclusive insight into everything [insert your niche]. Enjoy!",
            bodyParagraph3: '(DELETE AS REQUIRED)',
            bodyListItem1: 'Latest Updates:',
            bodyListItem1SubListItem1:
              "I've just wrapped up an amazing project on [topic/project], and I'm thrilled to give you an exclusive behind-the-scenes look. Check out the full story on [insert my site]!",
            bodyListItem2: 'Featured Content:',
            bodyListItem2SubListItem1:
              "In case you missed it, my latest video on [topic] went live last week. It's packed with actionable insights that you won't want to miss. Watch it here [link] and let me know what you think!",
            bodyListItem3: 'Upcoming Events:',
            bodyListItem3SubListItem1:
              "Mark your calendar! Join me on [date] at [time] for a live webinar where I'll be diving deeper into [topic/event]. Save your spot on this [link].",
            bodyListItem4: 'Exclusive Insights:',
            bodyListItem4SubListItem1:
              "Here's a tip that I haven't shared anywhere else yet—[share a unique tip or insight]. I've found it incredibly effective, and I hope it helps you too!",
            bodyListItem5: "Spotlight Interview with [Expert's Name]:",
            bodyListItem5SubListItem1:
              "This month, I had the fantastic opportunity to sit down with [Expert's Name], a pioneer in [industry/topic], and discuss [key topic]. Here's what they had to say [link or summary].",
            bodyListItem6: 'Tutorial: Mastering [Skill/Tool] in 5 Easy Steps:',
            bodyListItem6SubListItem1:
              "Whether you're a beginner or looking to refine your skills, follow these steps to enhance your [relevant skill or tool usage]. Dive into the guide [link].",
            bodyListItem7: 'Behind-the-Scenes at Our Latest Shoot/Event:',
            bodyListItem7SubListItem1:
              "Ever wonder what it takes to put together [type of content or event]? Here's a glimpse behind the scenes, showing you all the action and the people who make it happen [link or image].",
            bodyListItem8: "This Month's Top Pick:",
            bodyListItem8SubListItem1:
              "I've been reading [Book Name] by [Author], and it's been a game-changer for understanding [topic]. Highly recommend it for anyone looking to [objective of reading the book].",
            bodyParagraph4:
              'Thanks for reading, and as always, if you have any questions or feedback, feel free to reach out at [insert social handle].',
            bodyParagraph5: 'Looking forward to connecting more with you!',
            bodyParagraph6: 'Warm regards,',
            bodyParagraph7: '[Your Name]',
          },
        },
      },
      helpfulResources: {
        card: {
          title: 'Resources to Thrive',
          description:
            'Provide helpful tools, tips, articles or resources to help your audience grow.',
          badge: 'Nurture 🤩',
        },
        emails: {
          0: {
            subject: "Things I Can't Stop Thinking About 📚🎥🎧",
            bodyParagraph1: 'Hi there!',
            bodyParagraph2:
              "In this edition of our roundup, I'm excited to share with you some books, podcasts, articles, and videos I can't stop thinking about. Whether you're looking to improve your productivity, enhance your skills, or just find new ways to challenge yourself, I've got something for you.",
            bodyParagraph3: '(Add or delete as appropriate)',
            bodyListItem1:
              "[Website] - This website offers comprehensive insights into [brief description of the content and its relevance]. Perfect for beginners, it's a must-visit.",
            bodyListItem2:
              "[Shared folder] - Here you'll find a plethora of tools and resources that can help streamline your online endeavors. Their guides on [specific topic] are particularly enlightening.",
            bodyListItem3:
              "[Podcast] - If you're into podcasts, this one is a gem. It features interviews with successful online entrepreneurs, sharing their journeys and actionable tips.",
            bodyListItem4:
              '[YouTube Channel] - This channel is a treasure trove of tutorials and tips on, covering a range of topics from [specific topic] to [specific topic].',
            bodyListItem5:
              '[Blog] - For regular doses of inspiration and cutting-edge strategies, this blog is a go-to. Their articles on [specific topic] are particularly insightful.',
            bodyListItem6:
              "[Online Community] - Joining this community can provide you with support, networking opportunities, and insights from fellow online money makers. It's a great space for learning and growth.",
            bodyListItem7:
              '[Tool or App] - This tool/app can significantly enhance [insert what the app does] by [describe functionality or purpose], making it easier to achieve your goals.',
            bodyListItem8:
              "[Inspirational Book] - This book offers profound insights into the mindset and principles of [specific topic]. It's a must-read for anyone serious about their online journey.",
            bodyParagraph4:
              "If you find these helpful or have any specific areas you're curious about, let me know! I'm here to help you succeed.",
            bodyParagraph5: 'Warm regards,',
            bodyParagraph6: '[Your Name]',
          },
        },
      },
      behindTheScenes: {
        card: {
          title: 'Behind the scenes',
          description:
            "A behind-the-curtain view of what you're doing or thinking about at the moment",
          badge: 'Nurture 🤩',
        },
        emails: {
          0: {
            subject: '🥸 Exclusive Sneak Peek: A Day in My Life',
            bodyParagraph1: 'Hi there!',
            bodyParagraph2:
              "Today, I'm doing something I've never done before. I'm pulling back the curtain to share an unfiltered glimpse into my everyday life. Why? Because you're not just another subscriber; you're part of my journey, and I want to give you the inside scoop.",
            bodyParagraph3: '5:30 AM: Rise and Shine!',
            bodyParagraph4:
              "My day kicks off while most of the world is still snoozing. I grab a quick cup of coffee, which I admit is the secret sauce to my productivity. As I sip, I catch up on emails and plan my day. It's quiet, peaceful, and the perfect time for me to set my intentions.",
            bodyParagraph5: '7:00 AM: Workout Time',
            bodyParagraph6:
              "Next up, exercise! Whether it's yoga, a brisk walk, or a full-on gym session, moving my body first thing keeps my energy levels high and my mind clear. Today, it was a 30-minute HIIT session - and yes, it was as intense as it sounds!",
            bodyParagraph7: '9:00 AM: Content Creation',
            bodyParagraph8:
              "This is where the magic happens! I dive into creating content. From brainstorming new ideas to filming videos and snapping photos, I try to keep things fresh and exciting. Today, I'm working on a special project that I can't wait to share with you soon. ",
            bodyParagraph9: '12:00 PM: Lunch & Learn',
            bodyParagraph10:
              "I take a break to refuel with a quick lunch - today, it's my favorite: a Thai quinoa salad. I also spend this time catching up on articles, podcasts, or webinars. Continuous learning is a huge part of what keeps me inspired.",
            bodyParagraph11: '2:00 PM: Meetings and Collaboration',
            bodyParagraph12:
              "The afternoon often brings meetings with my team, collaborations with other creators, or live sessions with my followers. It's a whirlwind of activity where we brainstorm, laugh, plan, and sometimes, troubleshoot unexpected challenges.",
            bodyParagraph13: '6:00 PM: Wind Down',
            bodyParagraph14:
              "Dinner time is family time, and it's a non-negotiable part of my day. We cook together, talk about our days, and just enjoy each other's company. It's the perfect way to ground myself after a busy day.",
            bodyParagraph15: '8:00 PM: Reflection and Relaxation',
            bodyParagraph16:
              "Before I call it a day, I spend some time in reflection. I jot down what went well and what I could improve. Then, it's time to unwind, usually with a good book or a soothing meditation session.",
            bodyParagraph17: '10:00 PM: Lights Out',
            bodyParagraph18:
              "Recharging is essential, so I try to stick to a regular bedtime. A good night's sleep is my secret weapon for staying creative and energetic.",
            bodyParagraph19:
              "There you have it - a day in my life, unfiltered and real. I hope it gives you a little more insight into who I am, not just as a creator but as a person. This journey I'm on isn't just about making content; it's about making connections, and that means with you.",
            bodyParagraph20:
              "Let me know what part of my day intrigued you the most, or if there's anything else you're curious about. Your thoughts and feedback mean the world to me, and they help me create more of the content you love.",
            bodyParagraph21: 'Thanks for being here with me,',
            bodyParagraph22: '[Your Name]',
          },
        },
      },
      educational: {
        card: {
          title: 'Educational',
          description:
            'Share tips, tricks, or bonus training material to help educate your contacts',
          badge: 'Nurture 🤩',
        },
        emails: {
          0: {
            subject: '🔓 Unlock Exclusive Tips to Elevate Your Skills!',
            bodyParagraph1: 'Hi there!',
            bodyParagraph2:
              "I hope this message finds you thriving :) I'm thrilled to share some bonus training material with you that has helped many others in [your niche], and I believe it will benefit you too.",
            bodyParagraph3:
              "This Week's Spotlight: [Insert name of bonus training material]",
            bodyParagraph4:
              "Whether you're a complete beginner or even looking to refine your skills, this resource is something you shouldn't miss. Here's a sneak peek of what you'll learn:",
            bodyListItem1:
              '[Key Point 1]: Discover how to [brief description of what the point covers], which can help you [benefit].',
            bodyListItem2:
              "[Key Point 2]: I'll show you the step-by-step process to [action or technique], making it simpler and more effective.",
            bodyListItem3:
              '[Key Point 3]: Learn the common mistakes in [your niche] and how to avoid them, ensuring you get the best results every time.',
            bodyParagraph5:
              'So without further ado, here it is!  [Link to bonus training material]',
            bodyParagraph6:
              'Your growth is important to me, and I would love to hear your thoughts on this topic! Feel free to connect with me on [insert social handle] to share your feedback or ask any questions you might have.',
            bodyParagraph7:
              'Looking forward to helping you succeed and achieve your goals! ',
            bodyParagraph8: 'Warm regards,',
            bodyParagraph9: '[Your Name]',
          },
        },
      },
      productWaitlist: {
        card: {
          title: 'Product waitlist',
          description:
            'Test demand for a product idea by inviting contacts to register for early access',
          badge: 'Promote 🪩',
        },
        emails: {
          0: {
            subject: "🚀 You're invited! Get early access to my new product.",
            bodyParagraph1: 'Hi there!',
            bodyParagraph2:
              "I hope this email finds you thriving! I've been busy cooking up something special recently - I'm thrilled to share that I'm working on a brand new [insert description of product], and I want you  to be among the first to experience it!",
            bodyParagraph3: 'Why Join the Waitlist?',
            bodyListItem1:
              "Early Access: You'll get your hands on our new product before anyone else, giving you a head start on exploring all its features.",
            bodyListItem2:
              'Be a Trendsetter: Your feedback will actually be used to shape the final version of the product',
            bodyListItem3:
              "Exclusive Benefits: I'm rolling out the red carpet with special perks only available to our waitlist members",
            bodyParagraph4:
              "This is the beginning of something that will [insert description of transformation your product will deliver]. Whether you're looking to [insert benefits], enhance your [insert benefit], or streamline your [insert benefit], this product is for you.",
            bodyParagraph5: '“Sounds great, where do I sign-up?!”',
            bodyParagraph6:
              'Simply click the button below to join the waitlist and secure your spot at the front of the line.',
            bodyParagraph7:
              'In the meantime - stay tuned for more updates, and thank you for your continued support!',
            bodyParagraph8: 'Warm regards,',
            bodyParagraph9: '[Your Name]',
          },
        },
      },
      productLaunch: {
        card: {
          title: 'New Product Alert',
          description:
            'Maximize sales by announcing the launch of a new product to your contacts',
          badge: 'Promote 🪩',
        },
        emails: {
          0: {
            subject: '🚀 Your Transformation Is Here!',
            bodyParagraph1: 'Hi there!',
            bodyParagraph2:
              "I hope this message finds you bursting with excitement because I have something special that I can't wait to share with you!",
            bodyParagraph3:
              'Something that has been brewing behind the scenes and is now ready to dramatically shift the way you [relevant field, e.g., "manage your business," "improve your photography skills," "optimize your wellness routine"].',
            bodyParagraph4: '🚀 Introducing: [insert Product Name]! 🚀',
            bodyParagraph5:
              "This isn't your run-of-the-mill digital product - it's a [insert type of digital product] crafted meticulously to elevate your [applicable area] to new heights. Imagine conquering your challenges and achieving [specific outcomes, e.g., 'unprecedented growth in your business,' 'captivating photo art,' 'a balanced, healthier lifestyle'].",
            bodyParagraph6:
              'Why [Product Name] is a must-have? (delete as appropriate)',
            bodyListItem1:
              'Tailored expertise: Gain behind the scenes apply proven strategies to your [applicable area].',
            bodyListItem2:
              'Flexible learning: Access the material anytime, anywhere, and progress at your own pace—perfect for busy schedules.',
            bodyListItem3:
              "Practical Tools: Implement immediately with our [e.g., 'downloadable templates,' 'interactive checklists,' 'hands-on projects'], ensuring you're not just learning, but also doing.",
            bodyListItem4:
              'Interactive learning: Includes interactive quizzes and exercises to reinforce learning and ensure you can apply concepts in real-world scenarios.',
            bodyListItem5:
              'Community access: Comes packaged with Exclusive access to a private community of like-minded individuals, where you can share insights, ask questions, and network with peers.',
            bodyListItem6:
              'Exclusive Resources: Packaged with exclusive downloadable resources including my [insert download/template/ebook name]',
            bodyParagraph7:
              "[Product Name] is the result of countless hours, a ton of research, and my passion for bringing you the best. Why? Because you deserve products that aren't just good, but are tailor-made to boost your [specific outcomes, e.g., 'unprecedented growth in your business,' 'captivating photo art,' 'a balanced, healthier lifestyle'].",
            bodyParagraph8:
              'Ready to embrace the change? 🌟 Click below to access [Product Name] now!',
            bodyParagraph9: '(button): Get it now',
            bodyParagraph10: 'Stay excited,',
            bodyParagraph11: '[Your Name]',
          },
        },
      },
      upcomingEvents: {
        card: {
          title: 'Upcoming events',
          description:
            'Maximize attendance rates by promoting an upcoming workshop or webinar',
          badge: 'Promote 🪩',
        },
        emails: {
          0: {
            subject:
              "Subject: 🚀 Let's Level Up Together - Join My Upcoming Webinar! ",
            bodyParagraph1: 'Hi there!',
            bodyParagraph2:
              "I'm super excited to let you in on something special I've been working on: I'm hosting a webinar that's all about taking our [relevant field or interest] to the next level, and I'd love for you to be a part of it.",
            bodyListItem1: "Here's the scoop:",
            bodyListItem1SubListItem1: 'When: [Date and Time]',
            bodyListItem1SubListItem2: 'Where: Online - [Webinar Platform]',
            bodyListItem1SubListItem3: 'Duration: [Time Duration] minutes',
            bodyListItem2: "We'll cover some cool stuff:",
            bodyListItem2SubListItem1:
              "[Key Topic or Speaker 1] - We'll dive into [topic details] and I'll show you some awesome [specific skills or knowledge] that are perfect whether you're starting out or you've been at this for a while.",
            bodyListItem2SubListItem2:
              "[Key Topic or Speaker 2] - We're going to break down the latest in [topic area] and I'll share some strategies that could totally change the game for your [applicable area].",
            bodyListItem2SubListItem3:
              '[Interactive Session/Q&A] - Bring all your questions! This is your chance to get advice tailored just for you from some of the best folks in the biz.',
            bodyListItem3: 'Why you should totally join:',
            bodyListItem3SubListItem1:
              "Boost Your Skills - Pick up new insights and ideas that'll help you stand out.",
            bodyListItem3SubListItem2:
              'Practical Tips - Walk away with real steps you can use right away to see results.',
            bodyListItem3SubListItem3:
              'Meet Others - Hang out with other awesome people who are on the same path as you. You never know where these connections might lead!',
            bodyParagraph3:
              "I can't wait to share all this with you and see how it helps you rock your [relevant field or activity]. Space is limited, so don't wait too long to sign up!",
            bodyParagraph4: '(button): Sign-up here',
            bodyParagraph5: "Can't wait to see you there,",
            bodyParagraph6: '[Your Name]',
          },
        },
      },
      discountOrSale: {
        card: {
          title: 'Discount or sale',
          description:
            "Announce a recent price reduction to ensure your contacts don't miss out",
          badge: 'Promote 🪩',
        },
        emails: {
          0: {
            subject: '⏰ Unlock Savings on my Top Product - Limited Time Only!',
            bodyParagraph1: 'Hi there!',
            bodyParagraph2:
              "I'm thrilled to share something very special with you today! 🌟 For a limited time only, you can get your hands on [Product Name] at an exclusive discount - we're talking a jaw-dropping [Percentage]% OFF!",
            bodyParagraph3:
              "[Product Name] has already helped countless [target audience, e.g., 'photographers,' 'marketers,' 'educators'] like you to [mention main benefits, e.g., 'enhance their skills,' 'grow their business,' 'improve their workflow']. Here's what you can expect: ",
            bodyListItem1: 'Feature/benefit 1 - [Brief description]',
            bodyListItem2: 'Feature/benefit 2 - [Brief description]',
            bodyListItem3: 'Feature/benefit 3 - [Brief description]',
            bodyParagraph4:
              "Whether you're looking to [solve a specific problem] or simply want to take your [relevant activity] to the next level, [Product Name] is your go-to solution. And there's no better time to invest in your future than right now.",
            bodyParagraph5:
              "But hurry - this exclusive offer is available until [end date], and once it's gone, it's gone!",
            bodyParagraph6:
              'Ready to dive in? Click the button below to claim your discount and start [achieving specific goal] today!',
            bodyParagraph7: '(button): Get it now',
            bodyParagraph8:
              "Thank you for your continued support and trust. I can't wait to see what you achieve with [Product Name]!",
            bodyParagraph9: 'Warm regards,',
            bodyParagraph10: '[Your Name]',
          },
        },
      },
    },
    use: 'Use broadcast',
    viewMoreTemplates: 'View more templates',
    backToAll: 'Back to all broadcasts',
    back: 'Back',
    learnMoreBroadcasts: 'Learn more about broadcasts',
    backToBroadcasts: 'Back to broadcasts',
    learnMoreAbout: 'Learn more about',
    LearnMoreAboutLinkText: 'Broadcasts',
    save: 'Save',
    draft: 'Draft',
    blocked: 'Blocked',
    active: 'Sent',
    broadcastRecipients: 'Broadcast recipients',
    reachOutContacts:
      'Reach out to your contacts who haven’t opted-in to your emails or who have unsubscribed, so you can continue to nurture your relationship with them.',
    addEmail: 'Add email',
    purchasedPaidProduct: 'Purchased the paid product:',
    claimedLeadMagnet: 'Claimed the free product:',
    purchasedProduct: 'purchased: {{product}}',
    claimedProduct: 'claimed: {{product}}',
    sendEmailModal: {
      cancel: 'Cancel',
      submit: 'Do it!',
      title: 'Sure you want to send?',
      body: 'All contacts that match your recipient criteria will receive this email.',
    },
    selectContactsSegment:
      'Select recipients for this broadcast (you can select multiple)',
    searchOrClickDropdown: 'Search, or click the dropdown',
    nothingFound:
      "No 'Live' products available. Head to the Products tab to ensure any available products are 'Live'.",
    emailDetails: 'Email details',
    delete: 'Delete',
    sendTest: 'Send me a preview',
    subject: 'Subject',
    body: 'Body',
    newEmailSubject: 'New email subject',
    kyc: {
      heading: 'One last thing to join the email party! 🌍✨',
      descriptionTop:
        "We need your physical address for legal stuff, but don't fret—it's just for our records. Your secrets are safe with us!",
      descriptionBottom:
        'This address will NOT be visible or accessible to your contacts (no creepers allowed 🙅🏻‍♀️).',
      antiSpam: 'Learn more about Anti-Spam Compliance here.',
      enterAddress: 'Enter your address here',
      streetAddress: 'Street address',
      poBox: '(This can be a PO box or a virtual mailbox)',
      addressLine2: 'Apartment, suite, etc.',
      city: 'City',
      state: 'State/province',
      postalCode: 'ZIP/postal code',
      country: 'Country',
      submit: 'Submit',
      successMessage: 'Address saved successfully!',
    },
    contactOptions: {
      all_contacts: {
        name: 'All Contacts',
        description: 'Anyone that is subscribed.',
      },
      lead: {
        name: 'Leads only',
        description:
          'Subscribers that have claimed a lead magnet, but NOT purchased a product.',
      },
      customer: {
        name: 'Customers only',
        description: 'Subscribers that have purchased a product.',
      },
    },
    sendBroadcast: 'Send broadcast',
    saveBeforeSending: 'Save before sending',
    sendingBroadcast: 'Sending...',
    emailLimitMet: 'Email send limit reached for this month',
    sent: 'Sent',
    onDate: 'on {{date}}',
    testEmailSent: 'Success, head to your inbox to see the email preview!',
    broadcastSentMessage:
      'Hooray, your broadcast was sent! Way to go, connecting with your audience!',
    maliciousLinksDetected:
      'Email broadcast blocked due to malicious links detected.  Please remove links to unblock broadcast.  If you believe there was an error, reach out to us at beta@theleap.co',
    goToProducts: 'Take me to Products',
    emailMetrics: {
      delivered: 'Delivered',
      deliveredInfo:
        "Delivered: Number of sent emails that were successfully delivered to the recipient's inbox, so far",
      opened: 'Opened',
      openedInfo:
        'Opened: Percentage of delivered emails that were opened by the recipient, so far',
      clicked: 'Clicked',
      clickedInfo:
        'Clicked: Percentage of delivered emails in which a button was clicked by the recipient, so far',
    },
    multiEmailFlow: 'Multi email flow - click for individual email metrics',
  },
  AUTOMATION: {
    title: 'Email',
    tabs: {
      flow: 'Flows',
      broadcast: 'Broadcasts',
    },
    pageTitle: 'Email',
    goToProducts: 'Take me to Products',
    triggers: 'Triggers',
    multiEmailFlow: 'Multi email flow - click for individual email metrics',
    newFlow: 'New flow',
    addEmailFlow: 'Add email flow',
    minutes: 'minutes',
    hours: 'hours',
    days: 'days',
    afterTrigger: 'after trigger',
    afterPreviousEmail: 'after previous email',
    backToAll: 'Back to all flows',
    back: 'Back',
    save: 'Save',
    saveFlow: 'Save flow triggers',
    defaultFlowTitle: 'Untitled flow',
    modal: {
      name: 'Email flow name',
      submit: 'Add',
    },
    flowTrigger_other: '{{count}} Flow triggers',
    flowTrigger_one: '{{count}} Flow trigger',
    flowTriggers: 'Triggers',
    sendTest: 'Send me a preview',
    delete: 'Delete',
    deleteEmail: 'Delete email',
    cancel: 'Cancel',
    deleteEmailTitle: 'Are you sure?',
    deleteEmailBody:
      "Once deleted, there's no turning back. Your email will be lost 👻",
    selectStartingTrigger: 'Select starting trigger',
    addEmail: 'Add email',
    addVariable: 'Add variable',
    contactNameDescription:
      'Automatically insert your contact\'s name. If unavailable, "friend" is substituted.',
    productNameDescription:
      'Automatically insert the name of the product that triggered the flow.',
    nothingFound:
      "No 'Live' products available. Head to the Products tab to ensure any available products are 'Live'.",
    addData: 'Add data',
    selectTriggersFlow:
      'Select triggers that cause this flow to start (you can select multiple)',
    noProductsYet:
      "You don't have any products yet. Create a new product to use trigger",
    purchasesPaidProduct: 'Purchases the paid product:',
    claimsLeadMagnet: 'Claims the free product:',
    learnMoreAbout: 'Learn more about flows',
    flowSteps: 'Flow steps',
    titlePlaceholder: 'Email flow title',
    active: 'Active',
    draft: 'Draft',
    onHold: 'On hold',
    onHoldInfoLine1:
      "Oops! You've hit your email limit. Contacts already in this flow will complete it, but no new contacts will enter until next month.",
    onHoldInfoLine2:
      'Unlock unlimited emails now by upgrading to the {{planName}} plan!',
    blocked: 'Blocked',
    flowStatus: 'Flow status',
    flowActiveDescription:
      "Emails will be sent to contacts entering the flow, or already inside the flow. Set to 'Draft' to pause sends.",
    flowDraftDescription:
      "No emails will be sent, including to contacts already inside this flow. Set to 'Active' to resume sends.",
    testEmailSent: 'Success, head to your inbox to see the email preview!',
    confirmFlowStatusModal: {
      ACTIVE: {
        title: 'Ready to set this flow to Active?',
        description_zero:
          "Setting to 'Active' means that going forward, any contact that matches your triggers will receive emails.",
        description_one:
          "Setting to 'Active' means that going forward, any contact that matches your triggers will receive {{count}} email.",
        description_other:
          "Setting to 'Active' means that going forward, any contact that matches your triggers will receive {{count}} emails.",
        cancel: 'No thanks',
        confirm: 'Do it!',
      },
      DRAFT: {
        title: 'Are you sure you want to set to Draft?',
        description_zero:
          "Setting to 'Draft' means that no further emails will be sent. \nYou can always set this flow back to 'Active' later.",
        description_one:
          "Setting to 'Draft' means that going forward, any contact that matches your triggers will no longer receive {{count}} email. \nYou can always set this flow back to Active later.",
        description_other:
          "Setting to 'Draft' means that going forward, any contact that matches your triggers will no longer receive {{count}} emails. Contacts already in this flow will also receive no further emails. \nYou can always set this flow back to Active later.",
        cancel: 'No thanks',
        confirm: 'Do it!',
      },
    },
    time: {
      minutes_one: 'after {{count}} minute',
      minutes_other: 'after {{count}} minutes',
      hours_one: 'after {{count}} hour',
      hours_other: 'after {{count}} hours',
      days_one: 'after {{count}} day',
      days_other: 'after {{count}} days',
    },
    buysProduct: 'buys: {{product}}',
    claimsProduct: 'claims: {{product}}',
    deleteFlow: 'Delete flow',
    deletion: {
      title: 'Are you sure?',
      body: "Once deleted, there's no turning back. Your flow will be lost 👻",
      cancel: 'Cancel',
      doIt: 'Yes, delete it',
      deleting: 'Deleting...',
    },
    successMessage: {
      ACTIVE:
        'Flow is active! 🎉 Contacts will start entering the flow based on the trigger you have set.',
      DRAFT: 'Flow is in draft. No further contacts will enter the flow.',
      default: 'Awesome! Your flow has been successfully saved!',
    },
    genericError: 'Something went wrong, please try again.',
    maliciousLinksDetected:
      'Email flow blocked due to malicious links detected.  Please remove links to unblock flow.  If you believe there was an error, reach out to us at beta@theleap.co',
    use: 'Use flow',
    viewMoreTemplates: 'View more templates',
    mainTitle: 'Email flows',
    mainDescription:
      'Simplify how you connect with your audience using automated emails. Create your own flow or choose from one of our expertly designed creator templates.',
    newFlowPage: {
      title: 'New flow',
      description:
        'Select from our library of expert-crafted email templates. Use the flows as they are or add your own flair to personalize them.',
      try: 'Try',
      buildFromScratch: 'Build from scratch',
    },
    templates: {
      welcomeNote: {
        card: {
          title: 'Warm welcome',
          description:
            'Greet new subscribers or first-time buyers. Make them feel part of something special right from the start.',
          badge: 'Single email',
        },
        emails: {
          0: {
            subject: "🎉I'm so glad you're here!",
            bodyParagraph1:
              "A massive thank you from the bottom of my heart for your recent order. I'm beyond excited to welcome you to my community. You're now linked to a network of enthusiastic individuals all geared up to explore, create, and grow. And I'm here to guide you at every step of the way.",
            bodyParagraph2:
              'Here are three tips on how to get the most out of our connected universe:',
            bodyListItem1: 'Get social:',
            bodyListItem1SubListItem1:
              'Instagram: [insert instagram handle] - For a daily dose of inspiration and behind-the-scenes fun',
            bodyListItem1SubListItem2:
              'YouTube: [Insert YouTube channel] - Where I share in-depth tutorials and tips',
            bodyListItem1SubListItem3:
              'Facebook: [insert Facebook page/group] - Join the conversation and become part of our supportive community.',
            bodyListItem1SubListItem4:
              'Twitter: [insert Twitter handle] - Follow for quick updates, thoughts, and engaging chats.',
            bodyListItem1SubListItem5:
              "TikTok: [insert TikTok handle] - Entertaining, bite-sized content that's as fun as it is informative.",
            bodyListItem2:
              "Check out my [blog/website] at [insert URL]. It's your go-to resource for [describe the type of content, e.g., in-depth articles, free resources, event announcements]. Here are a few pieces I think you might enjoy:",
            bodyListItem2SubListItem1:
              '[link an existing video, article, or product here]',
            bodyListItem3:
              "Share your thoughts! Feel free to reach out to me on [insert social handle] to share your feedback, or just to say hi. I'm all ears and always thrilled to connect with members of our community.",
            bodyParagraph3:
              "I'm so grateful to have you as part of the community. Here's to a journey filled with learning, growth, and lots of fun.",
            bodyParagraph4: "Can't wait to see you shine!",
          },
        },
      },
      thankYou: {
        card: {
          title: 'Thank you',
          description:
            'Show your appreciation to customers for subscribing or buying a specific product. Include any helpful instructions or next steps.',
          badge: 'Single email',
        },
        emails: {
          0: {
            subject: "🎉 Thank You for Your Order! Here's How to Get Started",
            bodyParagraph1: 'Hey there!',
            bodyParagraph2:
              "From the bottom of my heart, thank you so much for your support. It means the world you've trusted me enough to be a part of this journey.",
            bodyParagraph3:
              "Inside {{product_name}}, you'll discover [insert brief mention of key features or content]. I've poured my heart and soul into this to ensure it truly enhances your [insert relevant context: learning, creativity, productivity etc].",
            bodyParagraph4:
              'Here are some helpful tips to help you get started: [insert tips, advice or information that will help your customer get started. For Leap products or Digital downloads, offer additional instructions/info. For Coaching, offer instructions on how to edit your booking, or prepare for the session. For Events,  links to the online event, info on the event itself and how to prepare for the session].',
            bodyParagraph5:
              'Your experience means everything to me. If you have any feedback, questions, or just want to share your excitement, feel free to send me a DM any time on [insert social handle]',
            bodyParagraph6: 'Thanks again!',
            bodyParagraph7: '[Sign off]',
          },
        },
      },
      salesFunnel: {
        card: {
          title: 'Nurture funnel',
          description:
            'Nurture leads naturally by sharing your story via an expertly crafted email sequence.',
          badge: 'Multi email',
        },
        emails: {
          0: {
            subject: "🌟Welcome aboard! Let's get started",
            bodyParagraph1: 'Hey there!',
            bodyParagraph2:
              "A big, warm welcome to you! I'm [insert your name], and I'm absolutely delighted to embark on this exciting journey with you.",
            bodyParagraph3:
              "First things first, let me tell you a bit about myself. I'm [briefly introduce yourself – your interests, what drives you in this business, and a fun or relatable personal tidbit]. I started my business with the goal of [brief mission or purpose of your brand], and every day, I'm inspired by stories and feedback from people like you.",
            bodyParagraph4:
              "You should have recently received {{product_name}} - you'll discover it contains [insert brief mention of key features or content]. I've poured my heart and soul into this to ensure it truly enhances your [insert relevant context: learning, creativity, productivity etc].",
            bodyParagraph5:
              "Over the next 10 days, you'll receive [4] more emails from myself, packed full of practical tips, tricks and guidance to keep you on track, see keep an eye on your inbox.",
            bodyParagraph6:
              "In the meantime, if you have any questions, need some advice, or just want to share your wins, don't hesitate to reach out. We're in this together, and your journey is what truly inspires me to keep creating and improving.",
            bodyParagraph7:
              "Here's to a journey filled with learning, growth, and lots of fun. Can't wait to see you shine!",
          },
          1: {
            subject: '🙈 How I almost gave up - the story I rarely share',
            bodyParagraph1: 'Hey there,',
            bodyParagraph2:
              "I hope you're finding {{product_name}} helpful and that it's sparking positive momentum! Today, I wanted to share something a bit more personal – a story from my journey, one of those less polished, behind-the-scenes chapters that led to where I am today.",

            bodyParagraph3:
              "Every story has its ups and downs, right? Mine is no exception. There was a moment when I nearly let go of my dream to [describe your dream or goal related to your business]. It feels like yesterday, even though it's a story of [time period].",
            bodyParagraph4:
              "[Begin your story. Describe the context, what you were striving for, or the vision you had. Then, delve into the challenge or the 'almost failure' moment. Detail the emotions, the stakes, and what was at risk. Keep it authentic and relatable.]",
            bodyParagraph5:
              "But here's the thing – it was precisely that moment, that low point, that became the turning point for me. I realized [describe the insight or revelation you had]. It wasn't easy, but I learned that [share a key lesson or principle you discovered].",
            bodyParagraph6:
              "And that's exactly why I created {{product_name}}. I wanted to [describe the purpose or mission of your product or brand, tying it back to the lesson learned]. Because if there's one thing my journey has taught me, it's [share a universal lesson or message].",
            bodyParagraph7:
              "So, why am I sharing this with you? Because I believe in transparency and in showing that the path to success isn't always a straight line. And more importantly, if you're facing hurdles right now, know that you're not alone. Every challenge is just a stepping stone, shaping you for the amazing things ahead.",
            bodyParagraph8:
              "I'd love to hear if my story resonated with you or if you've faced similar challenges. Your stories inspire me every day, and together, I believe we can turn any adversity into a strength.",
            bodyParagraph9:
              "Thank you for letting me share this piece of my journey with you. Here's to embracing our challenges and transforming them into our biggest victories!",
          },
          2: {
            subject:
              '😲 My biggest mistake: how I almost lost it all over this one silly thing',
            bodyParagraph1: 'Hey there,',
            bodyParagraph2:
              "I hope you've been enjoying our journey together so far! Today, I want to switch gears and share a story with you—a true tale from my own experience that I hope you'll find as enlightening as it was for me.",
            bodyParagraph3:
              'Picture this: I was at a pivotal moment in my [business/career/project], where every decision felt like it could make or break my success. Amid all the hustle, there was one thing I thought was too trivial to warrant my attention — a [insert the mistake in question, e.g., routine check, a basic setting on my website, an email subscription confirmation].',
            bodyParagraph4:
              "Yes, something as simple as that. Can you believe it? I overlooked it, disregarding it as unimportant amidst more 'significant' tasks. But here's the twist: that one oversight started a domino effect that nearly brought everything I'd worked for crashing down.",
            bodyParagraph5:
              '[Insert a brief, engaging narrative about what happened, the immediate consequences, and the emotional rollercoaster you went through. Keep it relatable and inject a bit of humor or self-deprecation to keep the tone light.]',
            bodyParagraph6:
              "In the end, the situation was salvaged, but not without some serious lessons learned. The biggest takeaway? There's no such thing as 'too small' in our [business/life/projects]. Every cog in the machine, no matter how minuscule it may seem, is crucial to the overall functionality.",
            bodyParagraph7:
              "So, why am I telling you this? Because I believe we can all learn from each other's mishaps. And if my mistake can save you from a similar fate, then sharing this story will have been worth it.",
            bodyParagraph8:
              "Have you ever faced a seemingly small oversight that turned into a big ordeal? How did you handle it? Let's turn our little blunders into big opportunities for growth together.",
            bodyParagraph9:
              "Looking forward to your stories and insights, and as always, if there's anything specific you'd like to know or discuss further, I'm just an email away.",
            bodyParagraph10:
              "Here's to turning our mistakes into stepping stones for success!",
          },
          3: {
            subject: '🚀 Five resources to turbocharge your journey!',
            bodyParagraph1: 'Hey there,',
            bodyParagraph2:
              "Me again :)  Today, I'm super excited to share something special with you – a handpicked list of resources that can turbocharge your journey!",
            bodyParagraph3:
              "Whether you're just starting out or looking to enhance your current strategy, these resources are goldmines of knowledge, tips, and tricks that can set you on the path to success. So, without further ado, let's dive in!",
            bodyParagraph4: '(Add or delete as appropriate)',
            bodyListItem1:
              "[Website] - This website offers comprehensive insights into [brief description of the content and its relevance]. Perfect for beginners, it's a must-visit.",
            bodyListItem2:
              "[Shared folder] - Here you'll find a plethora of tools and resources that can help streamline your online endeavors. Their guides on [specific topic] are particularly enlightening.",
            bodyListItem3:
              "[Podcast] - If you're into podcasts, this one is a gem. It features interviews with successful online entrepreneurs, sharing their journeys and actionable tips.",
            bodyListItem4:
              '[YouTube Channel] - This channel is a treasure trove of tutorials and tips on, covering a range of topics from [specific topic] to [specific topic].',
            bodyListItem5:
              '[Blog] - For regular doses of inspiration and cutting-edge strategies, this blog is a go-to. Their articles on [specific topic] are particularly insightful.',
            bodyListItem6:
              "[Online Community] - Joining this community can provide you with support, networking opportunities, and insights from fellow online money makers. It's a great space for learning and growth.",
            bodyListItem7:
              '[Tool or App] - This tool/app can significantly enhance [insert what the app does] by [describe functionality or purpose], making it easier to achieve your goals.',
            bodyListItem8:
              "[Inspirational Book] - This book offers profound insights into the mindset and principles of [specific topic]. It's a must-read for anyone serious about their online journey.",
            bodyParagraph5:
              'Remember, the key to success is not just in acquiring knowledge but in applying it. So, I encourage you to explore these resources, experiment with what resonates, and take bold steps forward. ',
            bodyParagraph6:
              "I'd love to hear about your experiences, discoveries, or any other resources you've found invaluable. Let's keep the conversation going and support each other in our paths to success.",
            bodyParagraph7: 'Happy exploring!',
          },
          4: {
            subject: '🏅 Unlock Your Full Potential with {{product_name}}',
            bodyParagraph1: 'Hey there,',
            bodyParagraph2:
              "I hope you've been enjoying this email series! Today, I'm excited to share something truly special with you – an opportunity that I believe could be a game-changer in your journey.",
            bodyParagraph3:
              "Introducing [high-ticket product name] – my premium solution designed specifically for those who are ready to [goal of high-ticket product] to unprecedented heights. This isn't just another product; it's a transformational experience that has already helped many to achieve remarkable success.",
            bodyParagraph4:
              "[High-ticket product name] is [briefly describe what it is – a course, a coaching program, etc.] crafted meticulously to guide you through the complexities of [goal of high-ticket product]. It's built on [key features or pillars, such as in-depth modules, personalized coaching, an exclusive community, etc].",
            bodyParagraph5:
              "By the end of [high-ticket product name], you'll not only have [specific knowledge/skill], but you'll also experience profound transformations in your [specific outcomes, like income, business growth, strategy refinement, etc.]. Imagine where you could be just [time frame] from now – [describe the envisioned success or achievement].",
            bodyParagraph6:
              "There's never been a better time to invest in [what the product helps achieve, e.g., your financial freedom, your online business]. With [context or reason, like current market trends, upcoming changes, etc.], positioning yourself with the right knowledge and tools is crucial.",
            bodyParagraph7:
              "I'm genuinely passionate about seeing you succeed, and that's why I've poured everything into [high-ticket product name]. If you're ready to take this step in your transformation, I'd be honored to guide you!",
            bodyParagraph8:
              "If you have any questions or wish to learn more, please don't hesitate to reach out. If you're ready to dive in, you can get started right now by clicking the button below!",
            bodyLink1: "Let's go!",
          },
        },
      },
      checkIn: {
        card: {
          title: 'Friendly check-in',
          description:
            'Reach out to your contacts after their subscription or purchase. A friendly check-in can show them you care and are there to help.',
          badge: 'Single email',
        },
        emails: {
          0: {
            subject: "🎉 How's It Going so far? I'm Here to Help!",
            bodyParagraph1: 'Hi there!',
            bodyParagraph2:
              "It's been a week since you ordered {{product_name}} and I just wanted to drop in and see how everything is going…",
            bodyParagraph3:
              "If there's anything you're unsure about or if you've hit a snag, I'm here for you! Whether it's a question about a technical hiccup, or just a general inquiry, please reach out to me on [insert social handle].",
            bodyParagraph4:
              "Thanks again for joining my community. I'm excited to be part of your journey and am looking forward to any insights you'd like to share.",
            bodyParagraph5:
              'Wishing you all the best and looking forward to hearing your thoughts!',
            bodyParagraph6: '[Sign off]',
          },
        },
      },
      testimonial: {
        card: {
          title: 'Testimonial',
          description:
            'Request feedback from your customers. Testimonials are invaluable in inspiring and persuading future customers.',
          badge: 'Single email',
        },
        emails: {
          0: {
            subject: '🎉 Could you help me share the love?',
            bodyParagraph1: 'Hi there!',
            bodyParagraph2:
              "I hope you're well and enjoying your journey so far! It's been a couple of weeks since you embarked on this adventure with us, and I'm curious about how {{product_name}} is fitting into your life…",
            bodyParagraph3:
              "If you're loving {{product_name}} and it's sparking joy or making a difference for you, would you consider sharing your experience? Your testimonial could encourage others that are considering embarking on the same journey.",
            bodyParagraph4:
              'All you need to do is respond directly to this email with your feedback - a couple of sentences is plenty!',
            bodyParagraph5:
              "Of course, if you have any feedback, questions, or need a helping hand with anything at all, feel free to reach out. I'm here to ensure you have the best experience possible.",
            bodyParagraph6:
              "Your support and insights are what make this community amazing, and I'm so grateful to have you with us.",
            bodyParagraph7: 'Thank you and talk soon,',
            bodyParagraph8: '[Sign off]',
          },
        },
      },
    },
    kyc: {
      heading: 'One last thing to join the email party! 🌍',
      descriptionTop:
        "We need your physical address for legal stuff, but don't fret—it's just for our records. Your secrets are safe with us!",
      descriptionBottom:
        'This address will NOT be visible or accessible to your contacts (no creepers allowed 🙅🏻‍♀️).',
      antiSpam: 'Learn more about Anti-Spam Compliance here.',
      enterAddress: 'Enter your address here',
      streetAddress: 'Street address',
      poBox: '(This can be a PO box or a virtual mailbox)',
      addressLine2: 'Apartment, suite, etc.',
      city: 'City',
      state: 'State/province',
      postalCode: 'ZIP/postal code',
      country: 'Country',
      submit: 'Submit',
      successMessage: 'Address saved successfully!',
    },
    editAddress: 'Edit physical address',
    validation: {
      base: {
        emptyTrigger: 'Oops, please add triggers before saving',
        default:
          'Invalid or missing information, please review the emails and try again',
        maliciousLinkDetected:
          'Malicious link detected. Email is blocked from sending.',
      },
      rules: {
        invalid_type: "Triggers can't be empty",
        custom: "Triggers can't be empty",
      },
      subject: {
        too_small: "Subject can't be empty",
      },
      body: {
        too_small: "Body can't be empty",
      },
      delay: {
        too_big: "Timing can't be more than 30 days",
        too_small: 'Timing needs to be at least 1 minute',
      },
      status: {
        emptyEmails:
          'You need to add an email to this flow in order to set to Active.',
        emptyRules:
          'You need to add a trigger to this flow in order to set to Active.',
      },
      emailLimit: {
        limitReached:
          "🪫 You've reached the monthly fair use limit. Email sends are now disabled, your limit will refresh on the 1st of next month.",
        approachingLimit:
          "Email sends going sky high! 🛩️ You've got {{emailsRemaining}} email sends left in the monthly fair use limit.",
      },
    },
    emailMetrics: {
      delivered: 'Delivered',
      deliveredInfo:
        "Delivered: Number of sent emails that were successfully delivered to the recipient's inbox, so far",
      opened: 'Opened',
      openedInfo:
        'Opened: Percentage of delivered emails that were opened by the recipient, so far',
      clicked: 'Clicked',
      clickedInfo:
        'Clicked: Percentage of delivered emails in which a button was clicked by the recipient, so far',
    },
  },
  calendar: {
    availability: {
      title: 'Availability',
      weeklyAvailability:
        'Set your regular weekly  hours for customer bookings.',
      bookingNotice: 'Booking notice: 24 hours',
      bookingNoticeCaption:
        "Bookings require a minimum notice of 24 hours, so you don't get surprise last minute bookings.",
      bookingHorizon: 'Booking horizon: 4 weeks',
      bookingHorizonCaption:
        'Booking can only be made within the next 4 weeks.',
      timeZone: 'Time zone',
      backToProduct: 'Back to Book my time product',
      save: 'Save',
      blockedDates: 'Blocked dates',
      blockedDatesDirections:
        'Make specific dates unavailable that differ from weekly availability.',
      addBlockedDatesBtnText: 'Add blocked date',
      unavailable: 'Unavailable',
      calendarModal: {
        unavailable: 'Unavailable',
        selectDate: 'Select a date to block off',
        hasBookedEvents: 'Has booked events',
        cancel: 'Cancel',
        addBlockedDate: 'Add blocked date',
      },
      startTime: { too_small: 'Availability start time cannot be blank' },
      endTime: {
        too_small: 'Availability end time cannot be blank',
        custom: 'End time cannot be before or the same as start time.',
      },
      successMessage:
        'Your availability is all set! 🎉 Time to see the bookings roll in',
    },
    events: {
      title: 'Events',
      card: {
        eventDate: 'Date',
        eventTime: 'Time',
        productName: 'Product',
        customerName: 'Name',
        customerEmail: 'Email',
        timeRange: '{{start}} - {{end}}',
      },
      modal: {
        eventDateTime: '{{date}} {{start}} - {{end}}',
        product: 'Product',
        participantDetails: 'Participant details',
        viewContact: 'View contact details',
      },
      upcoming: {
        localTimezone: 'Local timezone: {{timezone}}',
        localTimezoneCaption:
          "Event times are automatically updated to your browser's timezone, so you never miss a booking.",
        emptyState: {
          title: 'Seems like you have some free time 🛋️',
          description:
            '<0>Create a Book my time product</0> and get paid for your time and advice! Already have one? Try getting more events by <1>adjusting your availability</1>.',
        },
      },
      past: {
        localTimezone: 'Local timezone: {{timezone}}',
        localTimezoneCaption:
          "Event times are automatically updated to your browser's timezone, so you never miss a booking.",
        emptyState: {
          title: 'Seems like you have some free time 🛋️',
          description:
            '<0>Create a Book my time product</0> and get paid for your time and advice! Already have one? Try getting more events by <1>adjusting your availability</1>.',
        },
      },
    },
    connectGoogleCalendar: {
      title: 'Sync with Google calendar',
      upgrade: 'Upgrade to connect 🚀',
      error: 'Something went wrong, please try again later',
      description:
        'Sync your Google calendar to manage availability in one place. Create calendar events and meeting links for you and your client automatically.',
      button: {
        connect: 'Connect',
        stopSync: 'Stop syncing',
        sync: 'Start Syncing',
      },
      helpText: {
        connect: 'Connect Google calendar to sync',
        stopSync: 'Synced with Google calendar',
        sync: 'Google calendar',
      },
    },
  },
  contacts: {
    dashboard: {
      pageTitle: 'Contacts',
      upgrades: {
        remainingContacts_zero: 'No more contacts left on {{planName}}!',
        remainingContacts_one: 'Only 1 contact left on {{planName}}!',
        remainingContacts_other:
          'Only {{count}} contacts left on {{planName}}!',
        hiddenContacts_one: 'You have {{count}} contact waiting!',
        hiddenContacts_other: 'You have {{count}} contacts waiting!',
        upgradeToPlan: 'Try {{planName}} 🚀',
      },
      date: '{{val, datetime}}',
      totalCount: '{{count}} contacts',
      exportOrders: 'Export paid orders',
      exportLeads: 'Export contacts',
      exportUpgradeModal: {
        title: 'Excited to export?',
        text: 'Exporting your list is part of the {{planName}} plan. Upgrade to take advantage of this feature!',
        buttonText: 'Try {{planName}} 🚀',
      },
      importContacts: 'Import contacts',
      importContactsQuotaExceeded:
        'You’ve reached your {{importContactsLimit}} imported contacts limit! Please reach out to help@theleap.co if you need support',
      importContactsModal: {
        title: 'Import contacts',
        description:
          'Imported contacts will receive an opt-in email to confirm their subscription status.',
        availableImports: 'contacts available to add in your next import.',
        description2: 'Upload CSV file with the following columns: Email, Name',
        buttonText: 'Import contacts',
        successMessage:
          'Importing contacts could take a few minutes. We’ll send an email confirmation when it’s complete.',
        uploading: 'Uploading...',
        noFileSelected: 'No file selected',
      },
      importContactsFileUpload: {
        upload: 'Upload file',
        change: 'Change file',
        requirements: 'CSV file format, up to 5MB',
        fileSizeError: 'File size cannot exceed 5MB',
      },
      tableUpgradeOverlay: {
        title_one: '{{count}} contact is getting cold 🥶',
        title_other: '{{count}} contacts are getting cold 🥶',
        text: 'Upgrade to reach all your contacts',
        buttonText: 'Try {{planName}} 🚀',
      },
      emptyState: {
        paragraph1:
          "👋 Contacts will appear here once you've made a sale or captured a lead.",
        paragraph2: "Haven't created a product yet? What are you waiting for?!",
        tryLink: "Let's do it",
      },
    },
    details: {
      back: 'Back to Contacts',
      orderHistory: 'Order history',
      noOrders: 'No orders yet',
    },
    date: '{{val, datetime}}',
    tags: {
      subscribed: 'Subscribed',
      unsubscribed: 'Unsubscribed',
      customer: 'Customer',
      lead: 'Lead',
      requires_opt_in: 'Requires opt in',
      pending_opt_in: 'Pending confirmation',
      imported: 'Imported',
    },
    table: {
      mostRecentOrderDate: 'Last Order Date',
      email: 'Email',
      name: 'Name',
      addedOn: 'Added on',
      revenue: 'Revenue ({{value}})',
      revenueWithoutValue: 'Revenue',
      paidOrders: 'Paid orders',
      freeOrders: 'Free orders',
      tags: 'Tags',
      tagsCustomer: 'Status',
      tagsSubscriber: 'Subscription',
      productName: 'Product name',
      type: 'Type',
      date: 'Date',
      deletedProductSuffix: ' (DELETED)',
    },
    productType: {
      Course: 'Leap product',
      DigitalDownload: 'Download',
      Coaching: 'Book my time',
      Webinar: 'Webinar',
      Ebook: 'Ebook',
      EmailCapture: 'Sign-up form',
      AskMeAnything: 'Ask me Anything',
    },
  },
  common: {
    successMessage: "Awesome! We've successfully saved your work!",
  },
  legalAddressSuccessMessage:
    'Address saved successfully! You can now proceed with sending broadcasts or activating flows.',
  onboarding: {
    root: {
      tabHeader: 'Personalizing your Leap experience',
    },
    welcome: {
      hello: "Hi friend, we're so glad you're here 👋🙂",
      intro:
        "Let's start with just a couple of questions, so that we can give you the best experience possible.",
      letsDoIt: "Let's do it!",
    },
    gettingStarted: {
      header:
        'Which of the following best describes your intended use of The Leap?',
      options: {
        primaryBusiness:
          "I'm self-employed or I own a business, and I'll be using The Leap for this business.",
        existingSideHustle:
          "I'm self-employed or I own a business, but I'll be using The Leap for a separate side-hustle.",
        newSideHustle:
          "I have a day job, I'll be using The Leap for a side-hustle.",
        extraIncome:
          "I'm not currently working, I'll be using The Leap for some extra income.",
      },
      next: 'Next',
    },
    connectSocialAccount: {
      header: 'Which social media platform are you most active on?',
      subheader:
        "We'll use this to quickly set up your store and recommend products to build based on your content. We only access what's publicly available.",
      handle: "What's your handle?",
      next: 'Next',
    },
    setUpStore: {
      share: {
        header: 'Woohoo! Your store is ready 🎉',
        next: 'Add a product',
        skip: 'Skip and let me explore',
      },
      profile: {
        header: 'Add your profile and choose a layout',
        next: 'I’m done!',
        skip: 'Skip and let me explore',
      },
      theme: {
        header: 'Set up your store to start selling',
        next: 'Next',
        skip: 'Skip and let me explore',
        pickTheme: 'Choose store theme',
      },
    },
    whatIsNext: {
      header1: "Let's get to the good part 🏃‍♀️‍➡️",
      header2: 'How would you like to start?',
      skip: "Skip, I don't need any help",
      setUpStorefront: {
        title: 'Set up my creator store',
        duration: 'less than 2 minutes',
        cta: 'Set up store',
      },
      sellExistingProduct: {
        title: 'Sell an existing product or service',
        duration: 'less than 5 minutes',
        cta: 'Sell product',
      },
      createLeapProduct: {
        title: 'Create a mobile product with AI',
        duration: 'less than 10 minutes',
        cta: 'Create product',
      },
    },
    emailConfirmation:
      'An email will be sent to your registered address shortly.',
  },
  gettingStarted: {
    root: {
      tabHeader: 'Personalizing your Leap experience',
    },
    welcome: {
      hello: "Hi friend, we're so glad you're here 👋",
      intro:
        "Let's get your store set up in a few clicks, so you can start selling right away.",
      letsDoIt: "Let's do it!",
    },
    connectSocialAccount: {
      header: 'Choose your primary social platform',
      subheader:
        "We'll  use this to recommend products ideas based on your content. We only access what's publicly available.",
      handle: "What's your handle?",
      next: 'Next',
      skip: 'Skip and let me explore',
    },
    setUpStore: {
      share: {
        header: 'Your store is ready 🎉',
        addProductToStore: 'Add a product to store',
        skip: 'Skip and let me explore',
      },
      theme: {
        header: 'Set up your store',
        next: 'Next',
        skip: 'Skip and let me explore',
        pickTheme: 'Choose theme',
      },
    },
    whatIsNext: {
      header1: "Let's get to the good part 🏃‍♀️‍➡️",
      header2: 'How would you like to start?',
      skip: "Skip, I don't need any help",
      setUpStorefront: {
        title: 'Set up my creator store',
        duration: 'less than 2 minutes',
        cta: 'Set up store',
      },
      sellExistingProduct: {
        title: 'Sell an existing product or service',
        duration: 'less than 5 minutes',
        cta: 'Sell product',
      },
      createLeapProduct: {
        title: 'Create a mobile product with AI',
        duration: 'less than 10 minutes',
        cta: 'Create product',
      },
    },
    emailConfirmation:
      'An email will be sent to your registered address shortly.',
  },
  analytics: {
    productPageVisits: 'Product page visits',
    productPageVisitsInfo:
      'Use this to identify which products need more promotion. Visits can come from your store or direct product links.',
    productPageVisitsEmptyState: 'No visits to your product pages yet.',
    trafficSources: {
      title: 'Visitor sources',
      info: 'Shows where visitors to your link-in-bio store and/or product pages came from. Use this to see where your most engaged followers are, or track effectiveness of promo campaigns.',
      emptyState: 'No one has visited your store yet.',
      sources: {
        '(direct)': 'Direct via URL',
        '(not set)': 'No tracking available',
        hs_email: 'Hubspot email',
        hs_automation: "Hubspot's automation",
      },
      upgradeOverlay: {
        title: 'Upgrade to see where your visitors are coming from.🚦',
        buttonText: 'Upgrade to {{planName}}',
      },
      showMoreSources: 'See all sources',
      showLessSources: 'See less sources',
    },
    totalVisits: 'Visits',
    totalVisitsInfo:
      'Total visits to your link-in-bio store and/or product pages. A higher number than ‘Visitors’ means you’re getting repeat visits.',
    totalVisitsChart: {
      title: 'Visits over time',
      info: 'Breakdown of visits to your link-in-bio store and/or product pages over time. Multiple visits from the same person are counted.',
      emptyStateTitle: "Looks like you're new here!",
      emptyStateText:
        'Data will be available 24 hours after you signed up. See you soon 🙂',
      tooltip: {
        visit: 'Visits:',
      },
    },
    uniqueVisits: 'Visitors',
    uniqueVisitsInfo:
      'Unique people that visited your link-in-bio store and/or product pages. Each visitor is counted once.',
    leadsCount: 'Contacts',
    leadsCountInfo:
      'Total leads collected from your paid product and/or lead magnets.',
    totalOrders: 'Sales',
    totalOrdersInfo:
      'Total revenue made from your paid products before transaction fees.',
    subtitle: 'Your store performance in the last 30 days',
    subtitleInfo:
      'Activity from the previous 24 hours won’t be visible in any of these charts yet.',
    title: 'Analytics',
  },
  components: {
    CopyStoreUrlButton: {
      long: {
        copyUrl: 'Copy store link',
        copyUrlSuccess: 'Store link copied!',
        copyUrlError: 'Something went wrong',
      },
      short: {
        copyUrl: 'Copy',
        copyUrlSuccess: 'Copied!',
        copyUrlError: 'Sorry, failed.',
      },
    },
    DocumentEditor: {
      AiAction: {
        label: {
          generate: 'Generate',
          modify: 'Modify',
          keep: 'Keep',
          cancel: 'Cancel',
        },
        popover: {
          generate: {
            product: {
              title: 'Generate product description',
              descriptionLabel:
                'Write a brief description of your product or service',
            },
            flow: {
              title: 'Generate email body',
              descriptionLabel:
                'Write a brief description of your email goal and audience',
            },
          },
          modify: {
            title: 'How would you like to modify selected text?',
            descriptionLabel: 'Shorten, make more engaging, etc',
            suggestions: 'Suggestions',
            tone: 'Change tone to be more',
          },
        },
        modify: {
          suggestions: {
            simplify: 'Simplify',
            expand: 'Expand',
            shorten: 'Shorten',
            fixSpelling: 'Fix spelling',
            summarize: 'Summarize',
            keyPoints: 'Key points',
            rephrase: 'Rephrase',
            enhanceSEO: 'Enhance SEO',
          },
          tone: {
            professional: '🎓 Professional',
            friendly: '😁 Friendly',
            confident: '😎 Confident',
            passionate: '😍 Passionate',
          },
        },
      },
    },
  },
  invalidIntent: 'Invalid intent',
};
